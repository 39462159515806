$tiny: 10px;
$small: 12px;
$base: 14px;
$medium: 16px;
$large: 18px;
$large2x: 22px;
$large3x: 24px;

$lineHeightTiny: 100%;
$lineHeightSmall: 110%;
$lineHeightBase:  120%;
$lineHeightLarge: 150%;
