@import "src/styles/colors";

.notification {
  position: fixed;
  top: 30px;
  right: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 16px 16px 16px 24px;
  width: 368px;
  background-color: $white;
  box-shadow: 0 2px 8px rgb(0 0 0 / 25%);
  border-left: 8px solid $default;
  border-radius: 4px;
  z-index: 9999;
  transition: opacity 150ms ease-in-out;
  opacity: 0;
  pointer-events: none;

  &.isVisible {
    opacity: 1;
    pointer-events: auto;
  }

  &.error {
    border-left-color: $orange;
  }
}

.closeButton {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0;
  width: 32px;
  height: 32px;
  color: $gray;
  font-size: 26px;
  line-height: 1;
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
}
