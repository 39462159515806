/* stylelint-disable selector-class-pattern */
/* stylelint-disable no-descending-specificity */
/* stylelint-disable font-family-no-missing-generic-family-keyword */

@import "src/styles/colors";
@import "src/styles/fonts";

.react-datepicker,
input {
  padding: 0;
  font-family: "Open Sans", arial;
}

.react-datepicker {
  *,
  *::before,
  *::after {
    box-sizing: initial;
  }
}

.react-datepicker__month {
  margin: 0;
}

.react-datepicker__day {
  border-radius: 0 !important;
  margin: 0;
  padding: 8px;
  color: $default;
}

.react-datepicker__day-names {
  margin-bottom: -12px;
  margin-top: 5px;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::after {
  border-bottom-color: $default;
}

.react-datepicker__header {
  background-color: transparent;
  border-bottom: 0;
  padding-top: 0;
}

.react-datepicker__day--outside-month {
  color: $default;

  &:hover {
    color: $default;
  }
}

.react-datepicker__day--disabled {
  color: $gray_light !important;

  &:hover {
    color: $gray_light !important;
  }
}

.react-datepicker__navigation-icon::before {
  border-width: 2px 2px 0 0;
  border-color: $white;
  top: 9px;
}

.react-datepicker__current-month {
  background: $default;
  padding: 6px;
  font-weight: 400;
  color: $white !important;
  font-size: $base;
}

.rbc-current-time-indicator {
  background-color: $yellow;
}

.react-datepicker__day-name {
  margin: 0;
  padding: 8px;
  color: $gray;
  text-transform: uppercase;
}

.react-datepicker__day--selected {
  background: $yellow;
  color: $default;

  &:hover {
    background: $yellow;
  }

  &.react-datepicker__day--disabled {
    background-color: lighten($yellow, 30%);
  }

  &.react-datepicker__day--highlighted {
    position: relative;
    background: $yellow;
    color: $default;

    &:hover {
      background: $yellow;
      color: $default;
    }

    &::before {
      content: "";
      border: 3px solid $default;
      top: 0;
      left: 0;
      width: calc(100% - 6px);
      position: absolute;
      height: calc(100% - 6px);
    }
  }
}

.react-datepicker__day--highlighted {
  background: $default;
  color: $white;

  &:hover {
    background: $default;
  }
}

.react-datepicker__day--excluded {
  background: $gray_light;
  color: $white !important;

  &:hover {
    background: $gray_light;
    color: $white !important;
  }
}
