@import "src/styles/fonts";
@import "src/styles/colors";
@import "src/styles/breakpoints.module";

.fontColor {
  &-default {
    color: $default;
  }

  &-gray {
    color: $gray_light;
  }

  &-orange {
    color: $orange;
  }

  &-yellow {
    color: $yellow;
  }

  &-red {
    color: $red;
  }

  &-white {
    color: $white;
  }
}

.italic {
  font-style: italic;
}

.gutterBottom {
  &-small {
    padding-bottom: 5px;
  }

  &-base {
    padding-bottom: 15px;
  }
}

.lineHeight {
  &-tiny {
    line-height: $lineHeightTiny;
  }

  &-small {
    line-height: $lineHeightSmall;
  }

  &-base {
    line-height: $lineHeightBase;
  }

  &-large {
    line-height: $lineHeightLarge;
  }
}

.size {
  &-tiny {
    font-size: $tiny;
  }

  &-small {
    font-size: $small;
  }

  &-base {
    font-size: $base;
  }

  &-medium {
    font-size: $medium;
  }

  &-large {
    font-size: $large;
  }

  &-large2x {
    font-size: $large2x;
  }

  &-large3x {
    font-size: $large3x;
  }
}

@media (min-width: $sm) {
  .desktopSize {
    &-tiny {
      font-size: $tiny;
    }

    &-small {
      font-size: $small;
    }

    &-base {
      font-size: $base;
    }

    &-medium {
      font-size: $medium;
    }

    &-large {
      font-size: $large;
    }

    &-large2x {
      font-size: $large2x;
    }

    &-large3x {
      font-size: $large3x;
    }
  }
}

.textAlign {
  &-left {
    text-align: left;
  }

  &-center {
    text-align: center;
  }

  &-right {
    text-align: right;
  }
}

.textTransform {
  &-none {
    text-transform: none;
  }

  &-uppercase {
    text-transform: uppercase;
  }

  &-lowercase {
    text-transform: lowercase;
  }
}

.weight {
  &-300 {
    font-weight: 300;
  }

  &-400 {
    font-weight: 400;
  }

  &-600 {
    font-weight: 600;
  }

  &-700 {
    font-weight: 700;
  }

  &-800 {
    font-weight: 800;
  }
}
