$xxs: 320px;
$xs: 576px;
$sm: 768px;
$md: 992px;
$lg: 1200px;
$xl: 1600px;

:export {
  xxs: $xxs;
  xs: $xs;
  sm: $sm;
  md: $md;
  lg: $lg;
  xl: $xl;
}
